import React from "react";
import { Helmet } from "react-helmet"
import StandardLayout from "../layout/StandardLayout";

const ShipsAgency = () => {
    return (
      <StandardLayout>
        <Helmet>
          <title>Ships Agency - Burke Shipping Services</title>
        </Helmet>

        <div
          className="c-hero"
          style={{ backgroundImage: "url(/images/ships-agency-hero.jpg)" }}
        ></div>

        <div className="u-fill-1 u-pad-large u-pad-xlarge@m">
          <div className="l-wrap u-width-5 u-align-center">
            <h1 className="c-heading +h1 u-marg-bottom">Ships Agency</h1>
            <p className="u-font-large@m">
              Burke Shipping Services Ltd. has a proven track record in offering
              high quality shipping agency services to many shipping companies
              across the world.
            </p>
          </div>
        </div>

        <div className="c-media-text +background-image-fill">
          <div className="l-wrap">
            <div className="c-media-text__contents +contents-flipped">
              <div className="c-media-text__a">
                <div className="u-pad-top-bottom-xlarge@m">
                  <p className="u-font-large@m u-marg-bottom u-marg-bottom-large@m">
                    As shipping agents, Burke Shipping Services Ltd. can provide
                    your company with a large range of services during your
                    vessels call to Londonderry.
                  </p>

                  <p className="u-marg-bottom u-marg-bottom-large@l u-font-bold">
                    Services include:
                  </p>

                  <div className="l-grid +cols-1 +cols-2@m">
                    <div className="l-grid__item">
                      <ul>
                        <li>Bunkers</li>
                        <li>Admiralty Charts</li>
                        <li>Ships Chandlers</li>
                        <li>Ship Repair</li>
                        <li>Lifeboat Service</li>
                        <li>Diving Services</li>
                      </ul>
                    </div>
                    <div className="l-grid__item">
                      <ul>
                        <li>Crew Change</li>
                        <li>Trinity House Light Dues</li>
                        <li>Marine Surveyors</li>
                        <li>Technicians</li>
                        <li>Sludge Removal</li>
                        <li>Medical services</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="c-media-text__b">
                <div className="c-media-text__media">
                  <img src="/images/ships-agency-3.jpg" alt="Port Photo" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="u-fill-2 u-color-1 u-pad-large u-pad-xlarge@m">
          <div className="l-wrap">
            <div className="l-grid +cols-1 +cols-2@m">
              <div className="l-grid__item">
                <p className="u-font-large@m u-marg-bottom">
                  Burke Shipping Services Ltd has a wealth of experience in
                  dealing with all types of vessels including{" "}
                  <span className="u-font-bold">
                    Bulk Carriers, General Cargo, Heavy Lift, Oil Tankers and
                    Cruise ships.
                  </span>{" "}
                  The company also handles a variety of cargoes including{" "}
                  <span className="u-font-bold">
                    Agri-products, Coal, Fertilisers, Minerals, Glass, Timber
                    and various project cargoes.
                  </span>
                </p>
              </div>

              <div className="l-grid__item">
                <p className="u-font-large@m u-marg-bottom">
                  Our dedicated team are experienced in ship to ship lightering
                  of vessels at Londonderry anchorage and draw on their local
                  experience to ensure we provide the best service possible.
                </p>
                <p className="u-font-large@m">
                  Due to our unique position on the quayside, we are less than
                  500 metres from your vessel during its stay. Burke Shipping
                  Services Ltd. are the sole collector of Trinity House Light
                  Dues based in the Port of Londonderry.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="l-wrap">
          <div className="c-media-text +background-image-fill">
            <div className="c-media-text__contents">
              <div className="c-media-text__a">
                <div className="u-pad-top-bottom-xlarge@m">
                  <h3 className="u-marg-bottom c-heading +h3">
                    Pre – Arrival Documents
                  </h3>
                  <p className="u-font-large@m u-marg-bottom">
                    Prior to your vessel arriving in Londonderry port, please
                    send the following documents to our office email{" "}
                    <a href="mailto:agency@burkeshipping.co.uk">
                      agency@burkeshipping.co.uk
                    </a>{" "}
                    or <a href="/contact">Contact Us</a>
                  </p>
                  <ul className="u-marg-bottom-large">
                    <li>
                      <a href="/files/NMSW-FAL-1.xlsx">General Declaration</a>
                    </li>
                    <li>
                      <a href="/files/NMSW FAL 5 and 6.xlsx">Crew List</a>
                    </li>
                    <li>
                      <a href="/files/CERS3_Workbook_v1.2.3-lite.xlsx">
                        CERS Declaration
                      </a>
                    </li>
                    <li>
                      <a href="/files/ISPS Security.xlsx">
                        Security Declaration
                      </a>
                    </li>
                    <li>
                      <a href="/files/Health.xlsx">Port Health Declaration</a>
                    </li>
                    <li>
                      <a href="/files/NMSW-Waste.xlsx">Waste Declaration</a>
                    </li>
                  </ul>

                  <h3 className="u-marg-bottom c-heading +h3">
                    Additional Port Information
                  </h3>
                  <p className="u-font-large@m u-marg-bottom">
                    Please review prior to vessel arrivals.
                  </p>
                  <ul>
                    <li>
                      <a
                        href="/files/ISPS Information 2016.pdf"
                        target="_blank"
                      >
                        Port ISPS Information
                      </a>
                    </li>
                    <li>
                      <a href="/files/Waste management Plan 2019 Rev 6.doc">
                        Port Waste Management Plan
                      </a>
                    </li>
                    <li>
                      <a
                        href="/files/Pilotage Directions 2015.pdf"
                        target="_blank"
                      >
                        Port Pilotage Information
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="c-media-text__b">
                <div className="c-media-text__media">
                  <img src="/images/ships-agency-2.jpg" alt="Ship Cranes" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="c-splitter"></div>
      </StandardLayout>
    );
};

export default ShipsAgency;